import { FC, PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from "react"
import axios from "axios";
import { IGuild, ISound } from "../../models/app.models";
export type Auth = {
    apiToken: string;
    username: string;
}

export type GlobalContext = {
    guilds: IGuild[] | undefined;
    setGuilds: (guilds: IGuild[]) => void;
    isLoading: boolean;
}

export const globalContext = createContext<GlobalContext>({
    guilds: undefined,
    setGuilds: (guilds: IGuild[]) => { },
    isLoading: false,
});

export const useGlobalContext = () => useContext(globalContext);

export const GlobalContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [guilds, setGuilds] = useState<IGuild[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    useEffect(() => {
        if (!guilds) {
            (async () => {
                setIsLoading(true);
                const guilds = await getData();
                const seenSounds = localStorage.getItem('seenSounds');
                if (!seenSounds) {
                    localStorage.setItem('seenSounds', guilds.flatMap(g => g.sounds.map(s => s.id)).join(','));
                }
                setGuilds(guilds ?? []);
                setIsLoading(false);
            })();
        }
    }, [guilds])
    const data = useMemo(() => ({ guilds, setGuilds, isLoading }), [guilds, setGuilds, isLoading])

    return <globalContext.Provider value={data}>{children}</globalContext.Provider>
}

export const getData = async (): Promise<IGuild[]> => {
    return (await axios.get<IGuild[]>('/guilds'))?.data;
}