import { FC } from "react";
import { Sound } from "./Sound";
import { IGuild } from "../../models/app.models";
import { Grid } from "@mui/material";

export type SoundboardProps = {
    guild: IGuild;
}

export const Soundboard: FC<SoundboardProps> = ({ guild }) => {
    const sounds = guild.sounds ?? [];

    return <Grid container spacing={2}>
        {sounds?.map(s => <Sound key={s.id} sound={s} />)}
    </Grid>
}
