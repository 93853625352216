
import { useGlobalContext } from "../providers/GlobalContextProvider";
import { useMemo } from "react";
import _ from 'lodash';
import { GuildGroup } from "../components/GuildGroup";
import { Grid } from "@mui/material";

export const Home = () => {
    const { guilds, isLoading } = useGlobalContext();

    const guildGroups = useMemo(() => {
        if (!guilds?.length) {
            return [];
        }
        const groups = _.groupBy(guilds, (x) => x.tag);

        return Object.keys(groups).sort((a, b) => a?.toLowerCase() < b?.toLowerCase() ? -1 : 1).map(g => ({
            name: g === 'bread' ? 'OvenDota' : g === 'overboard' ? 'Overboard' : g,
            guilds: groups[g]
        }));
    }, [guilds])
    return <Grid item xs={12}>{isLoading ? <>Baking that sweet bread...</> : <>
        <Grid item xs={12}>
            {guildGroups.map(g =>
                <GuildGroup key={g.name} guilds={g.guilds} groupName={g.name} />
            )}
        </Grid></>}
    </Grid>
}