import { FC } from "react";
import { Soundboard } from "./Soundboard";
import { IGuild } from "../../models/app.models";
import { Grid, Typography } from "@mui/material";

export type GuildProps = {
    guild: IGuild;
}

export const Guild: FC<GuildProps> = ({ guild }) => {
    return <Grid container sx={{ marginTop: 4 }}>
        <Grid item xs={12} sx={{marginBottom: 2}}>
            {guild.icon ?
                <img style={{ borderRadius: '24px', display: 'inline-block' }} width={48} height={48} src={guild.icon_url} alt="" />
                : <div style={{ borderRadius: '24px', backgroundColor: 'black', width: '48px', height: '48px', display: 'inline-block' }}></div>}
            <Typography variant='h5' display='inline'>{guild.name}</Typography>
        </Grid>
        <Soundboard guild={guild} />
    </Grid>
}