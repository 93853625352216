import { Guild } from "../components/Guild";
import { IGuild } from "../../models/app.models";
import { FC, useMemo } from "react";
import { Grid, Typography } from "@mui/material";
export type GuildGroupProps = {
    guilds: IGuild[];
    groupName: string;
}
export const GuildGroup: FC<GuildGroupProps> = ({ guilds, groupName }) => {
    const sorted = useMemo(() => {
        return guilds?.sort((a, b) => (a.name === 'OvenDota' ? 'breadboard 1' : a.name).toLowerCase() < (b.name === 'OvenDota' ? 'breadboard 1' : b.name).toLowerCase() ? -1 : 1) ?? []
    }, [guilds]);
    return <Grid container sx={{ marginBottom: 8 }}>
        <Grid container>
            <Typography variant="h2">{groupName}</Typography>
        </Grid>
        <Grid container>
            {sorted.map(g =>
                <Guild key={g.id} guild={g} />
            )}
        </Grid>
    </Grid>
}