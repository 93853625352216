/* eslint-disable jsx-a11y/anchor-has-content */
import { FC, useState } from "react";
import { ISound } from "../../models/app.models";
import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

export type SoundProps = {
    sound: ISound;
    isNew?: boolean;
}

const isSoundNew = (soundId: string) => {
    let hasSeenAnySound = false;
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key?.startsWith('seenSounds')) {
            hasSeenAnySound = true;
            const value = localStorage.getItem(key);
            if (value && value.includes(soundId)) {
                return false;
            }
        }
    }

    return hasSeenAnySound;
}

const addSoundToStorage = (soundId: string) => {
    const key = getStorageWithRoom();
    let seenSounds = localStorage.getItem(key) ?? '';
    if (!seenSounds.includes(soundId)) {
        seenSounds += (seenSounds.length > 0 ? ',' : '') + soundId
        localStorage.setItem(key, seenSounds);
    }
}

const getStorageWithRoom = (): string => {
    for (let i = 0; i < 10000; i++) {
        const key = `seenSounds${i === 0 ? '' : '' + i.toString()}`
        const value = localStorage.getItem(key);
        if (!value || value.split(',').length < 50) {
            return key;
        }
    }

    return 'seenSounds';
}

export const Sound: FC<SoundProps> = ({ sound }) => {
    const [ref, setRef] = useState<HTMLAudioElement | undefined>();
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [isNew, setIsNew] = useState<boolean>(isSoundNew(sound.id));

    const updateSeenSounds = () => {
        if (isNew) {
            addSoundToStorage(sound.id);
            setIsNew(false);
        }
    }

    const handleClick = () => {
        if (!ref) { return }
        if (isPlaying) {
            ref.pause();
            ref.currentTime = 0;
            setIsPlaying(false);
        }
        else {
            ref.play();
            setIsPlaying(true);
        }
        updateSeenSounds();
    }

    const handlePause = () => {
        if (!ref) { return; }
        updateSeenSounds();
        setIsPlaying(false);
    }

    const handleDownloadClick: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        let ele = e.target as HTMLElement
        let url = ''
        let anchor: HTMLAnchorElement;
        if (ele.nodeName === 'A') {
            e.preventDefault();
            e.stopPropagation();
            anchor = ele as HTMLAnchorElement;
        }
        else if (ele.nodeName === 'BUTTON') {
            e.preventDefault();
            e.stopPropagation();
            anchor = (ele.querySelector('a') as HTMLAnchorElement);
        }
        else {

            e.preventDefault();
            e.stopPropagation();
            while (ele?.nodeName !== 'BUTTON') {
                ele = ele?.parentElement!;
            }
            anchor = ele.querySelector('a') as HTMLAnchorElement;
        }
        url = anchor.href;


        var xhr = new XMLHttpRequest();
        xhr.open('GET', url);
        xhr.responseType = 'arraybuffer';
        xhr.onload = function (res) {
            var blob = new Blob(
                [xhr.response],
                { 'type': 'audio/mp3' },
            );
            var urlBlob = URL.createObjectURL(blob);
            const newAnchor = document.createElement('a');
            newAnchor.href = urlBlob;
            newAnchor.download = anchor.download;
            newAnchor.click();
        };
        xhr.send();
        updateSeenSounds();
    }

    return (<Grid item xs={12} sm={6} md={4} lg={3}>
        <Paper variant="elevation" onClick={handleClick} sx={{ cursor: 'pointer', position: 'relative' }}>
            {!!ref && <IconButton onClick={handleClick}>{ref?.paused ? <PlayCircleIcon /> : <StopCircleIcon />}</IconButton>}

            <IconButton onClick={handleDownloadClick}><DownloadForOfflineIcon /><a href={sound.sound_url} download={`${sound.name}.mp3`} /></IconButton>
            <Typography display='inline'>{sound.name}</Typography>
            <audio ref={(element) => setRef(element!)} style={{ verticalAlign: 'middle' }} onPause={handlePause}>
                <source src={sound.sound_url} type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
            {isNew && <Box sx={{ color: 'green', position: 'absolute', right: '4px', top: '0px' }}><Typography>**New**</Typography></Box>}
        </Paper>
    </Grid>)
}