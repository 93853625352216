import React from 'react';
import { Home } from './pages/Home';
import { Grid } from '@mui/material';

export const App = () => {
  return (
    <Grid container xs={12} sx={{padding: 4}}>
      <Home />
    </Grid>
  );
}

export default App;
